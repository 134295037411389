<template>
    <div class="app-main">
        <slot/>
    </div>
</template>
<script setup>
</script>
<style lang="scss" scoped>
.app-main{
    height: 100%;
    width: 100%;
    max-width: 100dvw;
    min-height: 100dvh;
    // height: 100dvh;
    // width: 100dvw;
    margin: 0 auto;
    background-repeat: repeat;
    background-color: $base-room-color;
    background-blend-mode:multiply;
    display: flex;
    flex-direction: column;
    align-items: center;
    >:deep(*){
        min-width: 375px;
        max-width: 1024px;
    }
}

</style>